<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="appCampaign")
			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label App Campaign Information <span v-if="appCampaign.id">({{ appCampaign.id }})</span>
									.card-toolbar
								.card-body
									.row
										.col-sm-8
											.form-group
												label Name
												input.form-control(type="text", v-model="appCampaign.name")
										.col-sm-4
											.form-group
												label Status
												select.form-control(v-model="appCampaign.status")
													option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
									.row
										.col-sm-12
											.form-group
												b-form-checkbox(v-model="appCampaign.age_restricted", switch) Age Restricted
											.form-group
												b-form-checkbox(v-model="appCampaign.is_approved", switch) Approved

							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Info
									.card-toolbar
								.card-body

									.form-group
										label Advertiser
										select.form-control(v-model="appCampaign.advertiser_id")
											option(v-for="o in advertiserOptions", :value="o.v") {{ o.t }}

									.form-group
										label Preview URL
											=' '
											a(href="javascript:void(0);", @click="loadAssetInfo()")
												i.la.la-refresh
										input.form-control(type="text", placeholder="Preview URL", v-model="previewUrl")
									.form-group
										label Asset
										select.form-control(v-model="appCampaign.asset_id")
											option(v-for="o in assetOptions", :value="o.v") {{ o.t }}
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Payout
									.card-toolbar
								.card-body
									.row
										.col-sm-4
											.form-group
												label Payout Model
												select.form-control(v-model="appCampaign.payout_model")
													option(:value="null") - Select -
													option(value="CPI") CPI
													option(value="CPA") CPA
										.col-sm-4
											.form-group
												label Payout
												input.form-control(type="number", v-model="appCampaign.payout")

							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Description
									.card-toolbar
								.card-body
									.form-group
										label Description
										textarea.form-control(rows="3", v-model="appCampaign.description")
									.form-group
										label Conversion Flow
										textarea.form-control(rows="3", v-model="appCampaign.conversion_flow")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Tracking
									.card-toolbar
								.card-body
									.form-group
										label Click
										textarea.form-control(rows="3", v-model="appCampaign.click_url")
									//.form-group
										label Impression
										textarea.form-control(rows="3", v-model="appCampaign.impression_url")




</template>
<style lang="scss">
.hidden-field {
	opacity: 0; position: absolute;
	pointer-events: none; z-index: -1px;
	width: 1px; height: 1px;
	overflow: hidden;
}
</style>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

async function loadRoute(vm, to, from, next){

	let appCampaign = {};
	if (to.name === 'app-campaign-edit') {
		let entityId = +to.params.id;
		try {
			appCampaign = await Vue.$dwData.appCampaign.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load app campaign');
			return next(false);
		}
	} else {
		appCampaign = Vue.$dwData.appCampaign.newInstance();
	}

	let assetOptions = await Vue.$dwData.asset.getOptions(true);
	let advertiserOptions = await Vue.$dwData.advertiser.getOptions(true);

	return vm => {
		vm.appCampaign = appCampaign;
		vm.assetOptions = assetOptions;
		vm.advertiserOptions = advertiserOptions;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'AppCampaignForm',
	data(){
		return {
			busy: true,
			appCampaign: null,
			assetOptions: [],
			statusOptions: Vue.$dwData.general.campaignStatusOptions,
			advertiserOptions: [],
			previewUrl: ''
		};
	},
	methods: {

		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.appCampaign);
			try {
				let resp = await this.$api.post('app-campaigns/save', data);
				this.busy = false;
				this.$notify.success('App Campaign has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'app-campaign-list'});
				} else {
					this.appCampaign = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		},

		async loadAssetInfo(){
			if (this.busy){
				return;
			}
			if (!this.previewUrl){
				return;
			}
			this.busy = true;
			try {
				let data = {
					url: this.previewUrl
				};
				let resp = await this.$api.post('assets/fetch', data);
				if (resp.asset_id){
					this.appCampaign.asset_id = resp.asset_id;
					if (resp.is_new){
						this.assetOptions = await Vue.$dwData.asset.getOptions(true);
					}
				}
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		},

	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'App Campaigns', name: 'app-campaign-list'},
			{title: this.appCampaign && this.appCampaign.id ? 'Edit App Campaign' : 'Add App Campaign'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'app-campaign-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
